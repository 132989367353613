<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
        <div class="box-ui-select searchbar-content">
          <div class="title"><span>{{ $t('searchArea.date') }}</span></div>
          <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault" v-model:endDefault="endDefault" :withToday="withToday"/>
        </div>
        <!-- <div class="box-ui-select searchbar-content">
          <ui-check :id="'isBS'" :text="$t('searchArea.idlingbtn')" :class="'ui-check'" :checked="isBS" @setYn="onChangeBS"/>
        </div> 
        <div class="box-ui-select searchbar-content radioBtn">
          <div class="title"><span>{{ $t('common.allapply') }}</span></div>
          <input type="radio" value="isAll" v-model="selectedOption" checked>
        </div>
        <div class="box-ui-select searchbar-content radioBtn">
          <div class="title"><span>{{ $t('searchArea.idlexcept') }}</span></div>
          <input type="radio" value="isBS" v-model="selectedOption">
        </div>
        <div class="box-ui-select searchbar-content radioBtn">
          <div class="title"><span>{{ $t('searchArea.idlAppli') }}</span></div>
          <input type="radio" value="isDiff" v-model="selectedOption">
        </div>-->
      </div>
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('searchArea.id') }}</span></div>
           <div>
             <input type="text" class="mr-5" v-model="reqData.searchMemId" :placeholder="'ID'" @keyup.enter="setTableData"/>
             <button class="btn-search mr-5" type="button" @click="setTableData">
                <i class="fas fa-search"></i>
             </button>
           </div>
         </div>
      </div>
      <memo></memo>
    </div>
    <h3 class="tableName">{{ $t('searchArea.schResultTotal') }}</h3>
    <div class="totalBox min-w1600" v-if="outSearchTotal">
      <table class="mainTable">
        <tbody>
        <tr>
          <td>
            <ul class="money">
              <li><em>{{ $t('table.body.money') }}</em><span>{{ numberWithCommas(outSearchTotal.cashAmt) }}</span></li>
              <li><em>{{ $t('table.body.pointAmt') }}</em><span>{{ numberWithCommas(outSearchTotal.pointAmt) }}</span></li>
              <li><em>{{ $t('table.head.member') }}</em><span>{{ numberWithCommas(outSearchTotal.cntBottom) }}</span></li>
            </ul>
          </td>

          <td>
            <ul class="money">
              <li><em>{{ $t('table.body.userIn') }}</em><span>{{ numberWithCommas(outSearchTotal.userCashIn) }}</span></li>
              <li><em>{{ $t('table.body.partnerIn') }}</em><span>{{ numberWithCommas(outSearchTotal.userPayment) }}</span></li>
              <li><em>{{ $t('table.body.adminIn') }}</em><span>{{ numberWithCommas(outSearchTotal.adminPayment) }}</span></li>
            </ul>
          </td>
          <td>
            <ul class="money">
              <li><em>{{ $t('table.body.userOut') }}</em><span>{{ numberWithCommas( outSearchTotal.userCashOut) }}</span></li>
              <li><em>{{ $t('table.body.partnerOut') }}</em><span>{{ numberWithCommas(outSearchTotal.userPayback) }}</span></li>
              <li><em>{{ $t('table.body.adminOut') }}</em><span>{{ numberWithCommas(outSearchTotal.adminPayback) }}</span></li>
            </ul>
          </td>
          <td>
            <ul class="money">
              <li><em>{{ $t('table.body.in_out') }}</em><span>{{ numberWithCommas(outSearchTotal.userCashResultAmt) }}</span></li>
              <li><em>{{ $t('table.body.partnerin_out') }}</em><span>{{ numberWithCommas(outSearchTotal.partnerResultAmt) }}</span></li>
              <li><em>{{ $t('table.body.adminin_out') }}</em><span>{{ numberWithCommas(outSearchTotal.adminResultAmt) }}</span></li>
            </ul>
          </td>
          <td v-if="gameGroupList.includes('casino')">
            <ul class="money">
              <li class="head border-bot"><span>{{ $t('table.head.casino') }}</span></li>
              <li class="border-bot">
                <em>{{ $t('common.bet') }}</em>
                <span v-if="isAll">{{ numberWithCommas(outSearchTotal.casinoBetAmt) }}</span>
                <span v-if="isBS">{{ numberWithCommas(outSearchTotal.casinoBetAmtBS) }}</span>
                <span v-if="isDiff">{{ numberWithCommas(outSearchTotal.casinoBetAmt - outSearchTotal.casinoBetAmtBS) }}</span>
              </li>
              <li class="border-bot">
                <em>{{ $t('common.win') }}</em>
                <span v-if="isAll">{{ numberWithCommas(outSearchTotal.casinoBetAmtWin) }}</span>
                <span v-if="isBS">{{ numberWithCommas(outSearchTotal.casinoBetAmtWinBS) }}</span>
                <span v-if="isDiff">{{ numberWithCommas(outSearchTotal.casinoBetAmtWin - outSearchTotal.casinoBetAmtWinBS) }}</span>
              </li>
              <li class="border-bot">
                <em>{{ $t('common.rolling') }}</em>
                <span v-if="isAll">{{ numberWithCommas(outSearchTotal.casinoRollingAmt) }}</span>
                <span v-if="isBS">{{ numberWithCommas(outSearchTotal.casinoRollingAmt) }}</span>
                <span v-if="isDiff">0</span>
              </li>
              <li class="border-bot"><em>{{ $t('common.losing') }}</em><span>{{ numberWithCommas(outSearchTotal.casinoLosingAmt) }}</span></li>
              <li class="last">
                <em>{{ $t('common.cal') }}</em>
                <!-- <span
                  :class="{'rdc' : Number(!isBS ? outSearchTotal.casinoBetResultAmt : outSearchTotal.casinoBetResultAmtBS) > 0, 'blc': Number(!isBS ? outSearchTotal.casinoBetResultAmt : outSearchTotal.casinoBetResultAmtBS) <= 0}">
                    {{ numberWithCommas(!isBS ? outSearchTotal.casinoBetResultAmt : outSearchTotal.casinoBetResultAmtBS) }}
                  </span> -->
                <span v-if="isAll">{{ numberWithCommas(outSearchTotal.casinoBetResultAmt) }}</span>
                <span v-if="isBS">{{ numberWithCommas(outSearchTotal.casinoBetResultAmtBS) }}</span>
                <span v-if="isDiff">{{ numberWithCommas(outSearchTotal.casinoBetResultAmt - outSearchTotal.casinoBetResultAmtBS) }}</span>
              </li>
            </ul>
          </td>
          <td v-if="gameGroupList.includes('sport')">
            <ul class="money">
              <li class="head border-bot"><span>{{ $t('table.head.sport') }}</span></li>
              <li class="border-bot"><em>{{ $t('common.bet') }}</em><span>{{ numberWithCommas(outSearchTotal.sportBetAmt) }}</span></li>
              <li class="border-bot"><em>{{ $t('common.win') }}</em><span>{{ numberWithCommas(outSearchTotal.sportBetAmtWin) }}</span></li>
              <li class="border-bot"><em>{{ $t('common.rolling') }}</em><span>{{ numberWithCommas(outSearchTotal.sportRollingAmt) }}</span></li>
              <li class="border-bot"><em>{{ $t('common.losing') }}</em><span>{{ numberWithCommas(outSearchTotal.sportLosingAmt) }}</span></li>
              <li class="last"><em>{{ $t('common.cal') }}</em>
                <span
                  :class="{'rdc' : Number(outSearchTotal.sportBetResultAmt) > 0, 'blc': Number(outSearchTotal.sportBetResultAmt) <= 0}">
                          {{ numberWithCommas(outSearchTotal.sportBetResultAmt) }}
                        </span>
              </li>
            </ul>
          </td>
          <!-- <td v-if="!gameGroupList.includes('sport')">
            <ul class="money">
              <li class="head"><span>{{ $t('table.head.sport') }}</span></li>
              <li class="border-bot"><em>{{ $t('common.bet') }}</em><span>{{ numberWithCommas(outSearchTotal.sportBetAmt) }}</span></li>
              <li class="border-bot"><em>{{ $t('common.win') }}</em><span>{{ numberWithCommas(outSearchTotal.sportBetAmtWin) }}</span></li>
              <li class="border-bot"><em>{{ $t('common.rolling') }}</em><span>{{ numberWithCommas(outSearchTotal.sportRollingAmt) }}</span></li>
              <li><em>{{ $t('common.losing') }}</em><span>{{ numberWithCommas(outSearchTotal.sportLosingAmt) }}</span></li>
              <li class="last"><em>{{ $t('common.cal') }}</em>
                <span
                  :class="{'rdc' : Number(outSearchTotal.sportBetResultAmt) > 0, 'blc': Number(outSearchTotal.sportBetResultAmt) <= 0}">
                          {{ numberWithCommas(outSearchTotal.sportBetResultAmt) }}
                        </span>
              </li>
            </ul>
          </td> -->
          <td v-if="gameGroupList.includes('slot')">
            <ul class="money">
              <li class="head border-bot"><span>{{ $t('table.head.slot') }}</span></li>
              <li class="border-bot">
                <em>{{ $t('common.bet') }}</em>
                <span v-if="isAll">{{ numberWithCommas(outSearchTotal.slotBetAmt) }}</span>
                <span v-if="isBS">{{ numberWithCommas(outSearchTotal.slotBetAmtBS) }}</span>
                <span v-if="isDiff">{{ numberWithCommas(outSearchTotal.slotBetAmt - outSearchTotal.slotBetAmtBS) }}</span>
              </li>
              <li class="border-bot">
                <em>{{ $t('common.win') }}</em>
                <span v-if="isAll">{{ numberWithCommas(outSearchTotal.slotBetAmtWin) }}</span>
                <span v-if="isBS">{{ numberWithCommas(outSearchTotal.slotBetAmtWinBS) }}</span>
                <span v-if="isDiff">{{ numberWithCommas(outSearchTotal.slotBetAmtWin - outSearchTotal.slotBetAmtWinBS) }}</span>
              </li>
              <li class="border-bot">
                <em>{{ $t('common.rolling') }}</em>
                <span v-if="isAll">{{ numberWithCommas(outSearchTotal.slotRollingAmt) }}</span>
                <span v-if="isBS">{{ numberWithCommas(outSearchTotal.slotRollingAmt) }}</span>
                <span v-if="isDiff">0</span>
              </li>
              <li class="border-bot"><em>{{ $t('common.losing') }}</em><span>{{ numberWithCommas(outSearchTotal.slotLosingAmt) }}</span></li>
              <li class="last"><em>{{ $t('common.cal') }}</em>
                <!-- <span
                    :class="{'rdc' : Number(!isBS ? outSearchTotal.slotBetResultAmt : outSearchTotal.slotBetResultAmtBS) > 0, 'blc': Number(!isBS ? outSearchTotal.slotBetResultAmt : outSearchTotal.slotBetResultAmtBS) <= 0}">
                          {{ numberWithCommas(!isBS ? outSearchTotal.slotBetResultAmt : outSearchTotal.slotBetResultAmtBS) }}
                        </span> -->
                <span v-if="isAll">{{ numberWithCommas(outSearchTotal.slotBetResultAmt) }}</span>
                <span v-if="isBS">{{ numberWithCommas(outSearchTotal.slotBetResultAmtBS) }}</span>
                <span v-if="isDiff">{{ numberWithCommas(outSearchTotal.slotBetResultAmt - outSearchTotal.slotBetResultAmtBS) }}</span>
              </li>
            </ul>
          </td>
          <td v-if="gameGroupList.includes('minigame')">
            <ul class="money">
              <li class="head border-bot"><span>{{ $t('table.head.mini') }}</span></li>
              <li class="border-bot">
                <em>{{ $t('common.bet') }}</em>
                <span>{{ numberWithCommas(outSearchTotal.miniBetAmt) }}</span>
              </li>
              <li class="border-bot">
                <em>{{ $t('common.win') }}</em>
                <span>{{ numberWithCommas(outSearchTotal.miniBetAmtWin) }}</span>
              </li>
              <li class="border-bot">
                <em>{{ $t('common.rolling') }}</em>
                <span>{{ numberWithCommas(outSearchTotal.miniRollingAmt) }}</span>
              </li>
              <li class="border-bot"><em>{{ $t('common.losing') }}</em><span>{{ numberWithCommas(outSearchTotal.miniLosingAmt) }}</span></li>
              <li class="last"><em>{{ $t('common.cal') }}</em>
                <span
                    :class="{'rdc' : Number(outSearchTotal.miniBetResultAmt) > 0, 'blc': Number(outSearchTotal.miniBetResultAmt) <= 0}">
                          {{ numberWithCommas(outSearchTotal.miniBetResultAmt) }}
                        </span>
              </li>
            </ul>
          </td>
          <!-- <td v-if="!gameGroupList.includes('minigame')">
            <ul class="money">
              <li class="head"><span>{{ $t('table.head.mini') }}</span></li>
              <li class="border-bot"><em>{{ $t('common.bet') }}</em><span>{{ numberWithCommas(outSearchTotal.miniBetAmt) }}</span></li>
              <li class="border-bot"><em>{{ $t('common.win') }}</em><span>{{ numberWithCommas(outSearchTotal.miniBetAmtWin) }}</span></li>
              <li class="border-bot"><em>{{ $t('common.rolling') }}</em><span>{{ numberWithCommas(outSearchTotal.miniRollingAmt) }}</span></li>
              <li><em>{{ $t('common.losing') }}</em><span>{{ numberWithCommas(outSearchTotal.miniLosingAmt) }}</span></li>
              <li class="last"><em>{{ $t('common.cal') }}</em>
                <span
                    :class="{'rdc' : Number(outSearchTotal.miniBetResultAmt) > 0, 'blc': Number(outSearchTotal.miniBetResultAmt) <= 0}">
                          {{ numberWithCommas(outSearchTotal.miniBetResultAmt) }}
                        </span>
              </li>
            </ul>
          </td> -->
          <td>
            <ul class="money">
              <li class="head border-bot"><span>{{ $t('common.total') }}</span></li>
              <li class="border-bot">
                <em>{{ $t('table.body.totalBetAmts') }}</em>
                <!-- <span v-if="!isBS">{{ numberWithCommas(outSearchTotal.betAmt) }}</span>
                <span v-else>{{ numberWithCommas(outSearchTotal.betAmtBS) }}</span> -->
                <span v-if="isAll">{{ numberWithCommas(outSearchTotal.betAmt) }}</span>
                <span v-if="isBS">{{ numberWithCommas(outSearchTotal.betAmtBS) }}</span>
                <span v-if="isDiff">{{ numberWithCommas(outSearchTotal.betAmt - outSearchTotal.betAmtBS) }}</span>
              </li>
              <li class="border-bot">
                <em>{{ $t('table.body.totalWinAmts') }}</em>
                <!-- <span v-if="!isBS">{{ numberWithCommas(outSearchTotal.betAmtWin) }}</span>
                <span v-else>{{ numberWithCommas(outSearchTotal.betAmtWinBS) }}</span> -->
                <span v-if="isAll">{{ numberWithCommas(outSearchTotal.betAmtWin) }}</span>
                <span v-if="isBS">{{ numberWithCommas(outSearchTotal.betAmtWinBS) }}</span>
                <span v-if="isDiff">{{ numberWithCommas(outSearchTotal.betAmtWin - outSearchTotal.betAmtWinBS) }}</span>
              </li>
              <li class="border-bot">
                <em>{{ $t('table.body.totalRoll') }}</em>
                <!-- <span>{{ numberWithCommas(outSearchTotal.rollingAmt) }}</span> -->
                <span v-if="isAll">{{ numberWithCommas(outSearchTotal.rollingAmt) }}</span>
                <span v-if="isBS">{{ numberWithCommas(outSearchTotal.rollingAmt) }}</span>
                <span v-if="isDiff">{{ numberWithCommas(outSearchTotal.rollingAmt - outSearchTotal.rollingAmt) }}</span>
              </li>
              <li class="border-bot">
                <em>{{ $t('table.body.totalLose') }}</em>
                <span>{{ numberWithCommas(outSearchTotal.losingAmt) }}</span>
              </li>
              <li class="last">
                <em>{{ $t('table.body.totalCalAmt') }}</em>
                <span v-if="isAll">{{ numberWithCommas(outSearchTotal.betResultAmt) }}</span>
                <span v-if="isBS">{{ numberWithCommas(outSearchTotal.betResultAmtBS) }}</span>
                <span v-if="isDiff">{{ numberWithCommas(outSearchTotal.betResultAmt - outSearchTotal.betResultAmtBS) }}</span>
                <!-- <span v-if="!isBS" :class="{
                  'rdc' : Number(outSearchTotal.betResultAmt) > 0,
                  'blc': Number(outSearchTotal.betResultAmt) <= 0}">
                  {{ numberWithCommas(outSearchTotal.betResultAmt) }}
                </span>
                <span v-else :class="{
                  'rdc' : Number(outSearchTotal.betResultAmtBS) > 0,
                  'blc': Number(outSearchTotal.betResultAmtBS) <= 0}">
                  {{ numberWithCommas(outSearchTotal.betResultAmtBS) }}
                </span> -->
              </li>
            </ul>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="main-contents-wrapper min-w1600">
      <article class="total-info-container">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <img :src="require('@/assets/img/searchicon.png')" />
            <i class="fas fa-search"></i>
            <span>{{ $t('searchArea.schResult') }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value">{{ outSearchList ? outSearchList.length:0 }}</span>
          </div>
          <!--          <div class="total-info-item">-->
          <!--            <span class="title">{{ $t(`totalInfo.page`) }}</span> : <span class="value">{{ pageInfo.page }}</span>-->
          <!--          </div>-->
          <!--          <div class="total-info-item">-->
          <!--            <span class="title">{{ $t(`totalInfo.tatal_page_count`) }}</span> : <span class="value">{{ pageInfo.tatal_page_count }}</span>-->
          <!--          </div>-->
        </div>
      </article>
      <div class="table-wrapper">
        <all-to-table v-if="outSearchList"
                      :table="'main'"
                      :headInfo="headInfo"
                      :list="outSearchList"
                      :reqData="reqData" :key="reqData.endDate"
                      :level="0"
                      :isBS="isBS"
                      :isAll="isAll"
                      :isDiff="isDiff"
        />
      </div>
    </div>
    <!--    <pagination v-if="outSearchList.length !== 0" @goPage="goPage" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />-->
  </section>
</template>

<script>
import lodash from 'lodash'
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector'
import TableHead from '@/components/main/table/Head.vue'
import UiCheckSet from '@/components/ui/UiCheckSet'
import UiCheck from '@/components/ui/UiCheck'
import subTitle from '@/components/main/PageSubTitle'
import { replaceDateT, numberWithCommas, getDateStr } from '@/libs/utils.js'
import Pagination from '@/components/common/Pagination'
import { calculateSearch } from '@/api/report'
import AllToTable from '@/components/ui/report/allToTable.vue'
import { addDays } from 'date-fns'
import { mapState } from 'vuex'
import Memo from '@/components/common/memo'

export default {
  name: 'ReportTrade',
  inheritAttrs: false,
  components: {
    AllToTable,
    SearchFilter,
    DateSelector,
    TableHead,
    UiCheckSet,
    UiCheck,
    subTitle,
    Pagination,
    Memo
  },
  data: function () {
    return {
      headInfo: {
        fstColumn: false,
        dataList: ['memName', 'slotRate', 'casinoRate', 'miniRate', 'member', 'allBet', 'money', 'cashDiff', 'casino', 'slot', 'minigame']
      },
      reqData: {
        page: 1,
        count_per_list: '30',
        searchMemId: '',
        searchType: 'OUTER',
        chkTodayYn: 'Y',
        startDate: '',
        endDate: ''
      },
      pageInfo: {
        tatal_list_count: 0,
        page: 0,
        tatal_page_count: 0
      },
      outSearchList: null,
      outSearchTotal: {},
      startDefault: '',
      endDefault: '',
      moreToggle: false,
      totalText: false,
      withToday: false,
      isBS: false,
      isAll: true,
      isDiff: false,
      selectedOption: 'isAll'
    }
  },
  computed: {
    ...mapState([
      'gameGroupList'
    ])
  },
  watch: {
    selectedOption: function (newOption, oldOption) {
      this.isBS = false
      this.isAll = false
      this.isDiff = false
      this[newOption] = true
    }
  },
  methods: {
    numberWithCommas,
    onChangeBS (value) {
      this.isBS = value === 'Y'
    },
    game () {
      console.log('게임목록', this.gameGroupList)
    },
    todayCheck () {
      let endDate = ''
      if (this.$refs.today.checked) {
        this.reqData.chkTodayYn = 'Y'
        this.withToday = true
        endDate = new Date()
      } else {
        this.reqData.chkTodayYn = 'N'
        endDate = addDays(new Date(), -1)
        this.withToday = false
      }
      // console.log(endDate)
      // this.endDefault = endDate
      // this.reqData.endDate = getDateStr(endDate, "yyyy-MM-dd");
      // // this.reqData.endDate = getDateStr(this.endDefault, "yyyy-MM-dd");

      this.setTableData()
    },
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        _date = addDays(new Date(), 0)
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd')
    },
    setEndDate (date) {
      console.log('setEndDate : ', date)
      let _date = ''
      if (!date) {
        _date = addDays(new Date(), 0)
      } else {
        _date = date[0]
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd')
    },
    async setTableData () {
      this.emitter.emit('Loading', true)
      const today = getDateStr(new Date(), 'yyyy-MM-dd')
      if (this.reqData.endDate === today) {
        this.reqData.chkTodayYn = 'Y'
      } else {
        this.reqData.chkTodayYn = 'N'
      }
      console.log('[req] : ', this.reqData)
      const res = await calculateSearch(this.reqData)
      console.log(res)
      if (res.resultCode === '0') {
        this.outSearchList = res.data.outSearchList

        this.outSearchList.forEach(item => {
          item.casinoLosingAmt = (item.casinoBetAmt - item.casinoBetAmtWin - item.casinoRollingAmt) * (item.casinoLoseRate * 0.01)
          item.slotLosingAmt = (item.slotBetAmt - item.slotBetAmtWin - item.slotRollingAmt) * (item.slotLoseRate * 0.01)
          item.miniLosingAmt = (item.miniBetAmt - item.miniBetAmtWin - item.miniRollingAmt) * (item.miniLoseRate * 0.01)
          item.sportLosingAmt = (item.sportBetAmt - item.sportBetAmtWin - item.sportRollingAmt) * (item.sportLoseRate * 0.01)
        })

        this.outSearchTotal = res.data.outSearchTotal
      }
      this.emitter.emit('Loading', false)
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    if (this.$route.query.id) {
      this.reqData.memId = this.$route.query.id
    }
    this.setStartDate()
    this.setEndDate()
    this.game()
    await this.setTableData()
    this.emitter.emit('Loading', false)
  }
}
</script>

<style scoped>
   .tableName {padding: 20px 0 0; margin: 0 17px; font-size: 15px; font-weight: 600; color: #202020;}
   >>> .blc {color: #0f84ee;font-weight: bold;}
   >>> .rdc {color: #eb7a3f;font-weight: bold;}
   .mainTable td { border-bottom: 0; }
   .totalBox {box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.1);background-color: #efefef;box-sizing: border-box; margin: 10px 17px; border: 3px solid #efefef;}
   .totalBox td {border-right: 3px solid #f6f6f6;padding: 0;}
   .totalBox td:last-child, .mainTable td:last-child {border-right: 0;}
   .border-bot {border-bottom: 3px solid #efefef;}   .border-bot {border-bottom: 3px solid #f6f6f6;}

   .mainTable {table-layout: fixed;}
.mainTable >>> td {padding: 0;width: 100%;}
   .mainTable >>> td:nth-child(1) {width: 130%;}
   .mainTable >>> td:nth-child(3) {width: 120%;}
   .mainTable >>> td:last-child {width: 110%;}
   .mainTable tr:hover td {background: #fff;}

   /* .mainTable >>> .moreTable td {background: #f5f5f5;} */
   .mainTable >>> tbody:last-child .moreTable tr:last-child {border-bottom: 0;}
   .mainTable >>> .name {text-align: left;}
   .mainTable >>> td {position: relative;}
   .mainTable >>> td a.moreT img {width: 15px;}
   .mainTable >>> .name span {margin-left: 30px;display: flex;}

   >>> .moreT {cursor: pointer;}
   >>> .moreTable {width: 100%;}
   >>> .moreTable .moreTable {border: 0;}
   >>> .moreTable .moreTable .name::before {left: 35px;}
   >>> .moreTable .moreTable .moreTable .name::before {left: 45px;}
   >>> .moreTable .moreTable .moreTable .moreTable .name::before {left: 55px;}
   >>> .moreTable .moreTable .moreTable .moreTable .moreTable .name::before {left: 65px;}
   >>> .moreTable .moreTable td a.moreT::before {left: 45px;}
   >>> .moreTable .moreTable .moreTable td a.moreT::before {left: 55px;}
   >>> .moreTable .moreTable .moreTable .moreTable td a.moreT::before {left: 65px;}
   >>> .moreTable .moreTable .moreTable .moreTable .moreTable td a.moreT::before {left: 75px;}

   >>> .money li {text-align: left;padding: 0 10px;height: 30px;display: flex;align-items: center;justify-content: space-between;font-size: 12px;}
   >>> .money li.head {justify-content:center;font-weight: bold;border-bottom: 3px solid #f6f6f6;font-size: 13px;color: #585963;}
   >>> .money li.last {background: #fdf5ed;font-weight: bold;}

   >>> a.moreBtns {position: absolute;left: 5px;top: 50%;cursor: pointer;font-size: 22px;display: flex;align-items: center;justify-content: center;transition: all .2s ease-out;color: #0a7b0e;margin-top: -11px;}
   >>> .money li {text-align: left;padding: 0 17px;height: 24px;display: flex;align-items: center;justify-content: space-between; font-size: 12px;}
   >>> .money li.head {justify-content:center;font-weight: bold;}
   >>> .money li.last {background: #fdf5ed;}
   >>> a.moreBtns {position: absolute;left: 9px;top: 50%;cursor: pointer;font-size: 22px;display: flex;align-items: center;justify-content: center;margin-top: -7px; width: 14px;  height: 14px; background: #696969; border-radius: 2px; }
   >>> a.moreBtns::after {content: '+';display: inline-block; color: #fff; font-size: 14px; text-align: center;}
   >>> a.moreBtns.hiddenTable::after {content: '-'; font-size: 22px;}
   >>> a.moreBtns.else::after {content: '•'; font-size: 15px;}

   .searchBar{
    align-items: flex-end;
   }
   .ui-check {
    font-size:12px;
   }
   .table-wrapper {border:3px solid #efefef;padding: 0;}
   .radioBtn{
    flex-direction: row;
    align-items: center;
   }
</style>
